var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "no-close-on-backdrop": "" },
      on: {
        close: function ($event) {
          return _vm.onCloseModal()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.getTituloCertificado) + " ")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c("p", { staticClass: "text-center" }, [
        _vm._v("Se ha generado " + _vm._s(_vm.getTextoCertificado)),
      ]),
      _c(
        "b-col",
        { staticClass: "d-flex justify-content-center", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "outline-danger" },
              on: {
                click: function ($event) {
                  return _vm.sendForm()
                },
              },
            },
            [
              _c("i", { staticClass: "lar la-check-circle-right" }),
              _vm._v(" Aceptar "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }