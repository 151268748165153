<template>
  <b-modal centered hide-footer no-close-on-backdrop v-model="modal.isOpen" @close="onCloseModal()">
    <template #modal-title>
      {{ getTituloCertificado }}
    </template>
    <p class="text-center">Se ha generado {{ getTextoCertificado }}</p>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-button variant="outline-danger" @click="sendForm()">
        <i class="lar la-check-circle-right" /> Aceptar
      </b-button>
    </b-col>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
    datosModal: {
      type: Object,
      default: () => {
        return { tipo: null, mensaje: "" };
      },
    },
  },
  computed: {
    getTituloCertificado() {
      if (this.datosModal.tipo == "HISTORIAL") return `Historial de Pagos`;
      if (this.datosModal.tipo == "RENTA") return `Declaración de Renta`;
      return "";
    },
    getTextoCertificado() {
      if (this.datosModal.tipo == "HISTORIAL") return `el historial de pagos ${this.datosModal.mensaje}`;
      if (this.datosModal.tipo == "RENTA") return `la declaración de renta ${this.datosModal.mensaje}`;
      return "";
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal");
    },
    sendForm() {
      this.$emit("sendForm", this.datosModal.tipo);
      this.onCloseModal();
    },
  },
};
</script>
