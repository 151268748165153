import http from "@/app/core/config/axios.config";

export default class ReporteriaService {
  /* CERTIFICADOS ------------------------------------------------------------------------------------------------------------- */

  static generarCertificado(entry) {
    return http.get(
      `/reportes/Certificado?Certificado=${entry.tipo}&Obligacion=${entry.credito}&Usuario=${entry.usuario}&Anio=${entry.anio}&nom_Plantilla=${entry.plantilla}`,
      entry
    );
  }

  /* EXTRACTOS ---------------------------------------------------------------------------------------------------------------- */

  static generarExtracto(entry) {
    return http.get(
      `/reportes/Extracto?Anio=${entry.anio}&Mes=${entry.mes}&Credito=${entry.credito}&Email=${entry.email}&nom_Plantilla=PLAEXTRC`,
      entry
    );
  }

  /* HISTORIAL PAGOS ---------------------------------------------------------------------------------------------------------- */

  static crearHistorialPagos(entry) {
    let params = `numero_Producto=${entry.credito}&Certificado=${entry.tipo}&Usuario=${entry.usuario}&MayorJunio=${entry.mayorJunio}&nom_Plantilla=${entry.plantilla}`;
    return http.post(`/reportes/HistorialPagos/CreateHistorialPagos?${params}`);
  }

  static consultarHistorialPagos(entry) {
    return http.post(`/reportes/HistorialPagos/ConsultaHistorialPagos?numero_solicitud=${entry}`);
  }

  /* DECLARACIÓN DE RENTA ---------------------------------------------------------------------------------------------------- */

  static crearDeclaracionRenta(entry) {
    let params = `numero_Producto=${entry.credito}&Certificado=${entry.tipo}&Usuario=${entry.usuario}&Anio=${entry.anio}&nom_Plantilla=${entry.plantilla}`;
    return http.post(`/reportes/DeclaracionRenta/CreateDeclaracionRenta?${params}`);
  }

  static consultarDeclaracionRenta(entry) {
    return http.post(`/reportes/DeclaracionRenta/ConsultaDeclaracionRenta?numero_solicitud=${entry}`);
  }
}
